import React, { useState, useEffect } from "react";
import { settings } from "../../utils/tools";

const Hero = ({ heading, subheading, secondSubHeading, ...props }) => {
	const siteSetting = JSON.parse(localStorage.getItem("settings")) || {
		image:
		  "assets/images/incorpify_product_logo.png",
		meeting_link: "https://calendly.com/tascoutsourcing/30min",
		site_bg: "#ff6221",
		button_bg: "#ff6221",
		banner_bg: "#F7F7F7",
		banner_content:
		  "Hire an attitude, not just experience and qualification. Greg Savage.",
		header_bg: "#ff6221",
		head_title_color: "#ff6221",
	  };
	const [animation, setAnimation] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setAnimation(false);
		}, 300);
	}, []);
	return (
		<div
			className={`animation-container my-3 ${
				props?.forHome ? "mt-0" : "mt-4"
			} onboarding-margin-top-18 onboarding-min-height-120`}
			style={{ minHeight: subheading === "" ? "100px" : "160px" }}
		>
			<div
				className={animation ? "animation-content" : ""}
				style={{ transition: "transform 1s ease, opacity 2s ease" }}
			>
				<h1
					className={`header-heading1 ${
						props?.forHome ? "mt-0" : "mt-5"
					} fw-bold ff-ws onboarding-font-size-30`}
					style={{ color: siteSetting.head_title_color }}
				>
					{heading}
				</h1>
				<div className="w-100 d-flex flex-column justify-content-center align-items-center secondaryHeading">
					{/* @if ($subheading ?? false) */}
					<p
						className="secondry-heading header-heading3 mb-0"
						style={{ fontSize: "19px", color: '#928F8F', maxWidth: "900px" }}
					>
						{subheading}
					</p>
					{/* <p
						className="secondry-heading header-heading3 mb-0"
						style={{ maxWidth: "800px", fontSize: "19px", color: '#928F8F' }}
					>
						{secondSubHeading}
					</p> */}
					{/* @endif
            @if ($secondaryHeading ?? false) */}
					{props?.secondaryHeading && (
						<p
							className="header-heading3 mt-0 fs-6 text-secondary"
							style={{ width: "90vw", maxWidth: "800px" }}
						>
							{props.secondaryHeading}
						</p>
					)}
					{/* @endif */}
				</div>
			</div>
		</div>
	);
};

export default Hero;

import React, { useState } from "react";
import { Calendar, LogOut } from "../../assets/Icons";
import { Button, Drawer, Flex, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { removeSessions } from "../../utils/helper";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

const Header = ({ ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const siteSetting = JSON.parse(localStorage.getItem("settings")) || {
    image: "assets/images/incorpify_product_logo.png",
    meeting_link: "https://calendly.com/tascoutsourcing/30min",
    site_bg: "#ff6221",
    button_bg: "#ff6221",
    banner_bg: "#ff6221",
    banner_content:
      "Hire an attitude, not just experience and qualification. Greg Savage.",
    header_bg: "#f7f7f7",
    head_title_color: "#5ac063",
  };

  const [notification, setNotification] = useState(
    sessionStorage.getItem("notification") === "false" ? false : true
  );
  return (
    <>
      {loading && <Loader />}
      {notification && siteSetting.banner_content && (
        <div
          id="notification-banner"
          style={{ background: siteSetting.banner_bg }}
          className={`position-relative custom-banner banner w-100 ${
            !siteSetting.header_bg && "bg-success"
          }  text-center p-2`}
        >
          <div
            className="fs-7 banner-content"
            style={{
              paddingRight: "50px",
              paddingLeft: "50px",
              color: "#ffffff",
            }}
          >
            {siteSetting.banner_content}
          </div>
          <button
            onClick={() => {
              setNotification(false);
              sessionStorage.setItem("notification", false);
            }}
            id="remove-n-btn"
            style={{
              position: "absolute",
              right: 0,
              margin: "3px",
              height: "calc(100% - 16px)",
            }}
            className="remove-notification text-light p-0 top-0 mx-2 fs-6 px-2 outline-0 bg-transparent border-0"
          >
            <i className="bi bi-x-circle"></i>
          </button>
        </div>
      )}
      <header
        className="header-bar mb-auto mb-3  w-100"
        style={{ background: siteSetting.header_bg }}
      >
        <div className="container h-100 p-3 py-2 mx-auto d-flex align-items-center justify-content-between">
          <a href="/" className="text-decoration-none">
            <span className="header-logo float-md-start">
              <img height="80" src={siteSetting.image} alt="TASC logo" />
            </span>
          </a>
          <nav className="nav d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex nav-masthead justify-content-center align-items-center float-md-end onboarding-text-center onboardig-marginbottom">
            <span className="text-secondary fs-17">
              Welcome{" "}
              <span style={{ fontWeight: "bold" }}>
                {localStorage.getItem("userName")}
              </span>
            </span>
            <div className="onboarding-button d-flex">
              {location.pathname.includes("admin") ? (
                ""
              ) : (
                <Button
                  style={{
                    gap: "5px",
                  }}
                  className="hit-loading incorpify-secondary-btn d-flex align-items-center justify-content-around"
                  onClick={() => setIsHelpModalOpen(!isHelpModalOpen)}
                >
                  <span style={{ fontFamily: "Montserrat", fontSize: "13px" }}>
                    Book a meeting
                  </span>
                  <span
                    className="icon-btn_track"
                    style={{
                      height: "22px",
                      width: "30px",
                      paddingLeft: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Calendar />
                  </span>
                </Button>
              )}
              <Button
                type="primary"
                style={{
                  background: siteSetting.button_bg,
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  color: "#fff",
                  fontWeight: 700,
                }}
                className="hit-loading incorpify-primary-btn btn btn-to-link btn-secondary ms-3 btn-gradiant d-flex align-items-center justify-content-around"
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                    removeSessions();
                    navigate("/signin");
                  }, 3000);
                }}
              >
                <span>Log Out</span>
                <span
                  className="icon-btn_track"
                  style={{
                    height: "22px",
                    width: "30px",
                    paddingLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LogOut />
                </span>
              </Button>
            </div>
          </nav>
          <div className="d-block d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none lh-1">
            <MenuOutlined
              style={{ color: siteSetting.button_bg, fontSize: "20px" }}
              onClick={() => setOpenDrawer(true)}
            />
          </div>
        </div>
      </header>
      <Modal
        title=""
        open={isHelpModalOpen}
        onCancel={() => setIsHelpModalOpen(!isHelpModalOpen)}
        footer={null}
        className="service__form-modal"
        centered
        closeIcon={
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "#ffffff",
              borderRadius: "50%",
            }}
          >
            X
          </span>
        }
        style={{
          maxWidth: "1020px",
        }}
        width="100%"
      >
        <iframe
          src={
            siteSetting.meeting_link
              ? siteSetting.meeting_link
              : "https://calendly.com/tascoutsourcing/30min"
          }
          style={{
            border: 0,
            width: "100%",
            height: "91vh",
            paddingBottom: "25px",
            borderRadius: "8px",
          }}
          title="Calendly Scheduling"
        />
      </Modal>
      <Drawer
        title={
          <Flex justify="space-between">
            <span>Welcome, <b>{localStorage.getItem("userName")}</b></span>{" "}
            <CloseOutlined
              onClick={() => setOpenDrawer(false)}
              style={{ cursor: "pointer" }}
            />{" "}
          </Flex>
        }
        placement="right"
        closable={false}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        width="90%"
        key="right"
      >
        <Flex align="start" vertical gap={10}>
          <Button
            type="text"
            onClick={() => {
              setOpenDrawer(false);
              setIsHelpModalOpen(!isHelpModalOpen);
            }}
            className="d-flex align-items-center gap-1"
          >
            <span
              className="icon-btn_track"
              style={{
                height: "22px",
                width: "30px",
                paddingLeft: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Calendar />
            </span>
            <span style={{ fontFamily: "Montserrat", fontSize: "13px" }} className="fw-bold mt-[2px]">
              Book a meeting
            </span>
          </Button>
          <Button
            type="text"
            onClick={() => {
              setLoading(true);
              setOpenDrawer(false);
              setTimeout(() => {
                setLoading(false);
                removeSessions();
                navigate("/signin");
              }, 3000);
            }}
            className="d-flex align-items-center gap-1"
          >
            <span
              className="icon-btn_track"
              style={{
                height: "22px",
                width: "30px",
                paddingLeft: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LogOut />
            </span>
            <span className="fw-bold" style={{ fontFamily: "Montserrat", fontSize: "13px" }}>Log Out</span>
          </Button>
        </Flex>
      </Drawer>
    </>
  );
};

export default Header;

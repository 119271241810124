import { useEffect, useState } from "react";
import { fetchBoardIdsColumn, fetcher } from "../../utils/helper";
import { Button, Select } from "antd";
import Loader from "../common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { userSettingData } from "../../utils/tools";

export const AdminSettings = () => {
  const [loading, setLoading] = useState(false);
  const [fetchColumnData, setFetchColumnData] = useState(false);
  const [formColumns, setFormColumns] = useState([]);
  const [singlePageColumns, setSinglePageColumns] = useState([]);
  const [selectedColumn, setColumns] = useState({
    company: "",
    type_of_license: "",
    email: "",
    status: "",
    title: "",
    action: "",
    update: "",
  });
  const [uiData, setUiData] = useState({
    site_bg: "#ffffff",
    button_bg: "#ff6221",
    banner_bg: "#ff6221",
    banner_content: `"Hire an attitude, not just experience and qualification." Greg Savage 🚀`,
    header_bg: "#f7f7f7",
    head_title_color: "#434343",
  });
  const [logoData, setLogoData] = useState({ logo_name: "", logo_image: "" });
  const [meetingLink, setMeetingLink] = useState(
    "https://calendly.com/tascoutsourcing/30min"
  );
  const [boardOption, setBoardOption] = useState([]);
  const [saveButtonBg, setSaveButtonBg] = useState("#ff6221");
  const [boardId, setBoardId] = useState("");

  const handleChangeBg = (e) => {
    setUiData({ ...uiData, site_bg: e.target.value });
  };

  const handleChangeBgBtn = (e) => {
    setUiData({ ...uiData, button_bg: e.target.value });
  };

  const handleChangeLogo = async (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    if (file) {
      reader.onload = (function (theFile) {
        return function (e) {
          setLogoData({ logo_image: e.target.result, logo_name: file.name });
        };
      })(file);
      reader.readAsDataURL(file);
    }
  };

  const handleChangeBgBanner = (e) => {
    setUiData({ ...uiData, banner_bg: e.target.value });
  };

  const handleChangeHeaderBg = (e) => {
    setUiData({ ...uiData, header_bg: e.target.value });
  };

  const handleChangeHeadTitleColor = (e) => {
    setUiData({ ...uiData, head_title_color: e.target.value });
  };

  function startsWithHttp(url) {
    return (
      url.toLowerCase().startsWith("http://") ||
      url.toLowerCase().startsWith("https://")
    );
  }

  const handleChangeBannerText = (e) => {
    setUiData({ ...uiData, banner_content: e.target.value });
  };

  const checkColumnValues = () => {
    let valid = true;
    if (
      selectedColumn.company === "" ||
      selectedColumn.type_of_license === "" ||
      selectedColumn.company === null ||
      selectedColumn.type_of_license === null ||
      selectedColumn.email === null ||
      selectedColumn.email === ""
    ) {
      toast.error("Form Columns is required");
      valid = false;
    } else if (
      selectedColumn.title === "" ||
      selectedColumn.title === null ||
      selectedColumn.status === "" ||
      selectedColumn.status === ""
    ) {
      toast.error("Track Request Columns is required");
      valid = false;
    } else if (
      selectedColumn.action === "" ||
      selectedColumn.update === "" ||
      selectedColumn.action === null ||
      selectedColumn.update === null
    ) {
      toast.error("Single Page Columns is required");
      valid = false;
    }

    return { valid };
  };

  const fetchData = async () => {
    let method = "GET";
    let url = "/incorpify/saveSiteSettings";
    let response = await fetcher(url, method);
    if (response.status) {
      let uiSettings = JSON.parse(response.response.ui_settings);
      setLogoData({
        logo_image: response.response.logo_location,
        logo_name: response.response.logo_name,
      });
      setMeetingLink(response.response.meeting_link);
      setBoardId(response.response.board_id.toString());
      setUiData({
        site_bg: uiSettings.site_bg,
        button_bg: uiSettings.button_bg,
        banner_bg: uiSettings.banner_bg,
        banner_content: uiSettings.banner_content,
        header_bg: uiSettings.header_bg,
        head_title_color: uiSettings.head_title_color,
      });
      setColumns({
        company: uiSettings.selectedColumn.company,
        type_of_license: uiSettings.selectedColumn.type_of_license,
        email: uiSettings.selectedColumn.email,
        status: uiSettings.selectedColumn.status,
        title: uiSettings.selectedColumn.title,
        action: uiSettings.selectedColumn.action,
        update: uiSettings.selectedColumn.update,
      });
      setSaveButtonBg(uiSettings.button_bg);
    }
  };

  const handleSubmit = async () => {
    let url = "/incorpify/saveSiteSettings";
    let method = "POST";

    let payload = JSON.stringify({
      ui_settings: { ...uiData, selectedColumn },
      meeting_link: meetingLink,
      board_id: +boardId,
      logo_name: startsWithHttp(logoData.logo_image) ? "" : logoData.logo_name,
      logo_image: startsWithHttp(logoData.logo_image)
        ? ""
        : logoData.logo_image,
    });

    const { valid } = checkColumnValues();
    if (!valid) return;

    setLoading(true);
    try {
      let response = await fetcher(url, method, payload);
      if (response.status) {
        setTimeout(() => {
          toast.success(response.message);
          userSettingData();
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }, 4000);
      } else {
        toast.error("Something Went Wrong!");
        setLoading(false);
      }
    } catch (err) {
      toast.error("Something Went Wrong!");
      setLoading(false);
    }
  };

  const fetchBoardIds = async () => {
    setLoading(true);
    let option = [];
    try {
      const res = await fetcher("/incorpify/admin/getBoardIds", "GET");
      if (res.success) {
        res.data.boards.map((item) => {
          option.push({ value: item.id, label: item.name });
        });
        setBoardOption(option);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleChangeColumns = (value, key) => {
    setColumns({ ...selectedColumn, [key]: value });
  };

  const fetchColumns = async () => {
    setFetchColumnData(true);
    let columnData = await fetchBoardIdsColumn(boardId);
    if (columnData?.board) {
      setFormColumns([]);
      let boardColumn = [];
      columnData?.board.map((item) => {
        boardColumn.push({ value: item.id, label: item.title });
      });
      setFormColumns(boardColumn);
    } else {
      setFormColumns([]);
      setColumns({
        ...selectedColumn,
        name: null,
        type_of_license: null,
        email: null,
      });
    }
    if (columnData?.subitems?.column_values) {
      setSinglePageColumns([]);
      let pageColumn = [{ value: "name", label: "Subitem title" }];
      columnData?.subitems.column_values.map((item) => {
        pageColumn.push({ value: item.column.id, label: item.column.title });
      });
      setSinglePageColumns(pageColumn);
    } else {
      setSinglePageColumns([]);
      setColumns({
        ...selectedColumn,
        title: null,
        action: null,
        update: null,
        status: null,
      });
    }
    setFetchColumnData(false);
  };

  const filterOption = (input, option) => {
    return (
      option.label.toLowerCase().includes(input.toLowerCase()) ||
      option.value.toString().toLowerCase().includes(input.toLowerCase())
    );
  };

  const handleChangeBoardId = (value) => {
    setBoardId(value);
    setColumns({
      action: null,
      company: null,
      email: null,
      update: null,
      title: null,
      status: null,
      type_of_license: null,
    });
  };

  useEffect(() => {
    fetchBoardIds();
    fetchData();
  }, []);

  useEffect(() => {
    if (boardId !== "") {
      fetchColumns();
    }
  }, [boardId]);

  return (
    <>
      {(loading || fetchColumnData) && <Loader />}
      <div class="w-100 d-flex flex-column align-items-center p-2">
        <div class="col-md-7 col-lg-8 text-start">
          <h4 class="mb-3">
            <span class="mt-1 ms-2">General Settings</span>
          </h4>
          <hr />
          <div class="row g-3 mt-3">
            <div class="col-sm-6">
              <div class="col-sm-12 mb-5">
                <label for="site_bg" class="form-label fw-bold cursor-pointer">
                  Background-color&nbsp;<i class="bi bi-pen"></i>
                </label>
                <br />
                <input
                  type="color"
                  class="w-100 cursor-pointer"
                  name="site_bg"
                  id="site_bg"
                  value={uiData.site_bg}
                  required
                  onChange={handleChangeBg}
                />

                <small class="text-danger text-start ms-2"></small>
              </div>
              <div class="col-sm-12">
                <label
                  for="button_bg"
                  class="form-label fw-bold cursor-pointer"
                >
                  <span className="d-flex align-items-column">
                    <span>Button background-color</span>&nbsp;
                    <i class="bi bi-pen"></i>
                  </span>
                </label>
                <br />
                <input
                  type="color"
                  class="w-100 cursor-pointer"
                  id="button_bg"
                  name="button_bg"
                  value={uiData.button_bg}
                  required
                  onChange={handleChangeBgBtn}
                />

                <small class="text-danger text-start ms-2"></small>
              </div>
            </div>

            <div class="col-sm-6 ">
              <div class="">
                <label
                  for="logo_image"
                  class="form-label fw-bold cursor-pointer"
                >
                  Choose Logo Image&nbsp;<i class="bi bi-pen"></i>
                </label>
                <input
                  class="form-control cursor-pointer"
                  name="logo_image"
                  type="file"
                  id="logo_image"
                  onChange={handleChangeLogo}
                />

                <small class="text-danger text-start ms-2"></small>

                <div
                  id="imageContainer"
                  class="card  mt-2"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                    width: "150px",
                    minHeight: "90px",
                  }}
                >
                  {logoData.logo_image === "" ? (
                    <></>
                  ) : (
                    <img src={logoData.logo_image} alt="No preview" />
                  )}
                </div>
              </div>
            </div>

            <div class="col-12 row mt-3">
              <div class="col-sm-6">
                <label
                  for="banner_bg"
                  class="form-label fw-bold cursor-pointer"
                >
                  Banner background color&nbsp;<i class="bi bi-pen"></i>
                </label>
                <br />
                <input
                  type="color"
                  class="w-100 cursor-pointer"
                  id="banner_bg"
                  name="banner_bg"
                  value={uiData.banner_bg}
                  required
                  onChange={handleChangeBgBanner}
                />

                <small class="text-danger text-start ms-2"></small>
              </div>
              <div class="col-sm-6">
                <label
                  for="header_bg"
                  class="form-label fw-bold cursor-pointer"
                >
                  Header Background Color&nbsp;<i class="bi bi-pen"></i>
                </label>
                <br />
                <input
                  type="color"
                  class="w-100 cursor-pointer"
                  id="header_bg"
                  name="header_bg"
                  value={uiData.header_bg}
                  required
                  onChange={handleChangeHeaderBg}
                />

                <small class="text-danger text-start ms-2"></small>
              </div>
            </div>
            <div class="col-12">
              <div class="col-sm-12">
                <label
                  for="head_title_color"
                  class="form-label fw-bold cursor-pointer"
                >
                  Heading Title Color&nbsp;<i class="bi bi-pen"></i>
                </label>
                <br />
                <input
                  type="color"
                  class="w-100 cursor-pointer"
                  id="head_title_color"
                  name="head_title_color"
                  value={uiData.head_title_color}
                  required
                  onChange={handleChangeHeadTitleColor}
                />

                <small class="text-danger text-start ms-2"></small>
              </div>
            </div>
            <div class="col-12">
              <div class="col-sm-12">
                <label for="head_title_meeting" class="form-label fw-bold">
                  Meeting Link
                </label>
                <br />
                <input
                  type="text"
                  class="w-100"
                  id="head_title_color"
                  name="head_title_meeting"
                  value={meetingLink}
                  required
                  onChange={(e) => setMeetingLink(e.target.value)}
                />

                <small class="text-danger text-start ms-2"></small>
              </div>
            </div>
            <div class="col-12">
              <label
                for="banner_content"
                class="form-label fw-bold cursor-pointer"
              >
                Banner <span class="text-muted"></span>
              </label>
              <textarea
                type="text"
                class="form-control"
                name="banner_content"
                id="banner_content"
                placeholder="Enter the banner text content ."
                onChange={handleChangeBannerText}
                value={uiData.banner_content}
              ></textarea>

              <small class="text-danger text-start ms-2"></small>
            </div>
            <div className="col-12 mt-0">
              <div class="col-sm-12">
                <label for="incorpify_board" class="form-label fw-bold">
                  Select Board
                </label>
                <br />
                <Select
                  className="w-100"
                  value={boardId}
                  placeholder="Select Board"
                  onChange={(value) => handleChangeBoardId(value)}
                  options={boardOption}
                  showSearch
                  allowClear
                  placement="bottomLeft"
                  filterOption={filterOption}
                />

                <small class="text-danger text-start ms-2"></small>
              </div>
            </div>
            {boardId !== "" && (
              <div>
                <div className="mt-3 py-3 border" style={{borderRadius: '8px'}}>
                  <h5 className="text-secondary mb-3 pb-2 px-2 border-bottom">
                    Form Columns:
                  </h5>
                  <div class="col-12 row  mx-auto">
                    <div class="col-sm-6">
                      <label for="company" class="form-label fw-bold">
                        Company column
                      </label>
                      <br />
                      <Select
                        className="w-100"
                        placeholder="--Select Column--"
                        value={selectedColumn.company}
                        onChange={(value) =>
                          handleChangeColumns(value, "company")
                        }
                        options={formColumns}
                      />
                    </div>
                    <div class="col-sm-6">
                      <label for="type_of_license" class="form-label fw-bold">
                        Type Of License column
                      </label>
                      <br />
                      <Select
                        className="w-100"
                        options={formColumns}
                        value={selectedColumn.type_of_license}
                        onChange={(value) =>
                          handleChangeColumns(value, "type_of_license")
                        }
                        placeholder="--Select Column--"
                      />
                    </div>
                  </div>
                  <div class="col-12 row  mx-auto mt-3">
                    <div class="col-sm-12">
                      <label for="company" class="form-label fw-bold">
                        Email column
                      </label>
                      <br />
                      <Select
                        className="w-100"
                        placeholder="--Select Column--"
                        value={selectedColumn.email}
                        onChange={(value) =>
                          handleChangeColumns(value, "email")
                        }
                        options={formColumns}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-3 py-3 border" style={{borderRadius: '8px'}}>
                  <h5 className="text-secondary mb-3 pb-2 px-2 border-bottom">
                    Track Request Columns:
                  </h5>
                  <div class="col-12 row  mx-auto">
                    <div class="col-sm-6">
                      <label for="company" class="form-label fw-bold">
                        Title column
                      </label>
                      <br />
                      <Select
                        className="w-100"
                        placeholder="--Select Column--"
                        value={selectedColumn.title}
                        onChange={(value) =>
                          handleChangeColumns(value, "title")
                        }
                        options={singlePageColumns}
                      />
                    </div>
                    <div class="col-sm-6">
                      <label for="type_of_license" class="form-label fw-bold">
                        Status column
                      </label>
                      <br />
                      <Select
                        className="w-100"
                        options={singlePageColumns}
                        value={selectedColumn.status}
                        onChange={(value) =>
                          handleChangeColumns(value, "status")
                        }
                        placeholder="--Select Column--"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-3 py-3 border" style={{borderRadius: '8px'}}>
                  <h5 className="text-secondary mb-3 pb-2 px-2 border-bottom">
                    Single Page Columns:
                  </h5>
                  <div class="col-12 row  mx-auto">
                    <div class="col-sm-6">
                      <label for="company" class="form-label fw-bold">
                        Action column
                      </label>
                      <br />
                      <Select
                        className="w-100"
                        options={singlePageColumns}
                        value={selectedColumn.action}
                        onChange={(value) =>
                          handleChangeColumns(value, "action")
                        }
                        placeholder="--Select Column--"
                      />
                    </div>
                    <div class="col-sm-6">
                      <label for="type_of_license" class="form-label fw-bold">
                        Documents column
                      </label>
                      <br />
                      <Select
                        className="w-100"
                        options={singlePageColumns}
                        value={selectedColumn.update}
                        onChange={(value) =>
                          handleChangeColumns(value, "update")
                        }
                        placeholder="--Select Column--"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <hr class="my-4" />

            <Button
              style={{
                background: saveButtonBg,
                color: "white",
                border: "none",
              }}
              onClick={handleSubmit}
            >
              SAVE SETTINGS
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
};

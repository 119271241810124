import { fetcher } from "./helper";

export const settings = {
  link_btn_bg: "#ff6221",
  link_btn_color: "#FFFF",
  link_headtitle: "#F78D30",
  header_bg: "#F7F7F7",
  notification_bg: "#ff6221",
  logo: "https://res2.yourwebsite.life/res/662e321c735dad000f29c8ca/664a4695168299d05c440a35",
  heading: "Incorpify",
  hero_description:
    "Streamline Your Business Incorporation in Saudi Arabia or Get Personalized Guidance with a Free Consultation The Choice is Yours!",
};

export const userSettingData = async () => {
  // const role = getRole();
  let endpoint = `/incorpify/saveSiteSettings`;
  // let endpoint = role === 'customer' ? `governify/customer/governifySiteSetting` : `governify/admin/governifySiteSetting`;
  let method = "GET";
  const response = await fetcher(endpoint, method);
  if (response.status) {
    let uiData = JSON.parse(response.response.ui_settings);
    let data = {
      image: response.response.logo_location,
      meeting_link: response.response.meeting_link,
      ...uiData,
    };
    localStorage.setItem("settings", JSON.stringify(data));
  }
};

export const getUserDetails = async () => {
  let token = localStorage.getItem('userToken');
  if(token)
  try {
    const res = await fetcher(
      `/loginUserDetails/${token}`,
      "GET"
    );
    if (res.success) {
      localStorage.setItem("userEmail", res.data.email);
      localStorage.setItem("userName", res.data.name);
      localStorage.setItem('createdAt', res.data.created_at);
      localStorage.setItem("phoneNumber", res.data.phone);
      localStorage.setItem('userId', res.data.user_id);
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const checkIsItemCreated = async () => {
  let settings = JSON.parse(localStorage.getItem('settings'));
  let isCreated = false;
  let payload = {
    email: localStorage.getItem("userEmail"),
    limit: 1000,
    skip: 0,
    email_column_id: settings?.selectedColumn.email,
    overall_status: settings?.selectedColumn.status,
  };
  let endpoint = `/incorpify/getSubItemByEmail`;
  let method = "POST";
  const response = await fetcher(endpoint, method, JSON.stringify(payload));
  if (response.success) {
    if (response?.data?.response?.data?.boards[0].items_page.items.length > 0) {
      isCreated = true;
    } else {
      isCreated = false;
    }
  }

  return isCreated;
};

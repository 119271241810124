export function getDateAndTime(time) {
  let date = new Date(time);
  let day = date.getDate();
  let month = date.toLocaleString("default", { month: "long" });
  let year = date.getFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();

  let newDate = `${day} ${month.slice(0, 3)} ${year} at ${hour}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
  return newDate;
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import { fetcher, isTokenValid } from "../../utils/helper";
import { getUserDetails, userSettingData } from "../../utils/tools";
import { getBrowserInfo, isUserVerified } from "../../utils/CommonHelper";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [userDetails, setUserDetails] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  let token = queryParameters.get("token");
  let role = queryParameters.get("role");
  let id = queryParameters.get("id");
  let path = queryParameters.get("path");
  let count = queryParameters.get("count");

  const checkTwoFactorAuthenticated = (response) => {
    let browserInfo = getBrowserInfo();
    let verified = isUserVerified(
      localStorage.getItem("userId"),
      browserInfo.browser,
      browserInfo.os
    );
    if (response.role === "customer") {
      // if (verified) {
        if (sessionStorage.getItem("redirectUrl") !== null) {
          if (sessionStorage.getItem("redirectUrl").includes("signin")) {
            navigate("/");
            sessionStorage.removeItem("redirectUrl");
          } else {
            window.location.href = sessionStorage.getItem("redirectUrl");
            sessionStorage.removeItem("redirectUrl");
          }
        }
        setTimeout(() => {
          navigate("/");
        }, 1000);
      // } else {
      //   setTimeout(() => {
      //     navigate("/signin/two-factor");
      //   }, 1000);
      // }
    } else {
      if (sessionStorage.getItem("redirectUrl") !== null) {
        if (sessionStorage.getItem("redirectUrl").includes("signin")) {
          navigate("/admin-setting");
          sessionStorage.removeItem("redirectUrl");
        } else {
          window.location.href = sessionStorage.getItem("redirectUrl");
          sessionStorage.removeItem("redirectUrl");
        }
      }
      navigate("/admin-setting");
    }
  };

  const handleSubmit = async () => {
    let url = "/commom-login";
    let method = "POST";
    let payload = JSON.stringify({
      email: userDetails.email,
      password: userDetails.password,
      domain: "incorpify",
    });

    try {
      setLoading(true);
      const response = await fetcher(url, method, payload);
      if (response.status) {
        toast.success("Logged In Successfull.");
        localStorage.setItem("userToken", response.token);
        localStorage.setItem("role", response.role);
        sessionStorage.removeItem("random_user");
        await getUserDetails();
        await userSettingData();
        checkTwoFactorAuthenticated(response);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error");
      toast.error("Login Failed.");
    } finally {
      setLoading(false);
    }
  };

  
  const handleChangeUserDetails = (e, filter) => {
    setUserDetails({ ...userDetails, [filter]: e.target.value });
  };
  
  const checkEmailIsFilledAndValid = () => {
    const email = userDetails.email;
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,6}$/;
    if (email && emailRegex.test(email)) {
      return false;
    }
    return true;
  };
  
  const checkPasswordIsFilledValid = () => {
    const password = userDetails.password;
    if (password.length > 6) {
      return false;
    }
    return true;
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if(!checkEmailIsFilledAndValid() && !checkPasswordIsFilledValid()) {
        handleSubmit();
      }
    }
  };

  const adminLogin = async () => {
    let status = isTokenValid(token);
    if (status.valid) {
      localStorage.setItem("userToken", token);
      localStorage.setItem("role", role);
      await getUserDetails();
      await userSettingData();
      if (id && role === "customer") {
        localStorage.setItem("subItemId", id);
        localStorage.setItem("count", count);
        navigate(`/${path}`);
      } else if (role === "customer") {
        navigate("/");
      } else {
        navigate("/admin-setting");
      }
    } else {
      navigate("/signin");
    }
  };

  useEffect(() => {
    if (token) {
      adminLogin();
    }
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(false);
    }, 300);
  }, []);
  if (token) {
    return <Loader />;
  }

  return (
    <div className="inc-auth-container">
      <div className="container auth-container text-center">
        {loading && <Loader />}
        <div className="cover-container w-100 h-100 pb-2">
          <div className="">
            <div className="animation-container" style={{ minHeight: "90px" }}>
              <div
                className={`header-heading1 ${
                  animation ? "animation-content" : ""
                } ff-ws `}
                style={{
                  transition: "transform 1s ease, opacity 2s ease",
                  fontSize: "50px",
                  fontWeight: "500",
                }}
              >
                Incorpify
              </div>
            </div>
            <div className="form-container mx-auto">
              <div>
                <div>
                  {/* <span className="inc-tasc-gradient-btn">TASC</span><span className="fs-48 ff-ws"> 360</span> */}
                  <img
                    src="/newLogo.svg"
                    alt="TASC logo"
                    style={{ maxWidth: "220px" }}
                  />
                </div>
                <div className="fs-24 ff-ws mb-2 text-inc-tundora fw-600">
                  Sign In
                </div>
              </div>
              <div className="form-auth">
                <form action="" className="form-auth" onKeyDown={handleKeyDown}>
                  <input
                    placeholder="Email"
                    type="email"
                    value={userDetails.email}
                    onChange={(e) => handleChangeUserDetails(e, "email")}
                    className="form-control"
                  />
                  <div className="input-group flex-nowrap" id="password-filled">
                    <input
                      className="form-control"
                      id="input-password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={userDetails.password}
                      onChange={(e) => handleChangeUserDetails(e, "password")}
                    />
                    <span
                      className="input-group-text fs-5 encrypted"
                      style={{
                        cursor: "pointer",
                        borderRadius: "0 50px 50px 0px",
                      }}
                    >
                      {showPassword ? (
                        <i
                          className="bi bi-eye-fill"
                          onClick={() => setShowPassword(false)}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye-slash-fill"
                          onClick={() => setShowPassword(true)}
                        ></i>
                      )}
                    </span>
                  </div>
                  <button
                    id="login-button"
                    className="btn btn-to-link btn-secondary btn-gradiant mt-4 d-flex align-items-center"
                    type="button"
                    onClick={handleSubmit}
                    disabled={
                      checkEmailIsFilledAndValid() ||
                      checkPasswordIsFilledValid()
                    }
                  >
                    <span className="fw-bold">Sign In</span>
                    <span className="icon-btn_track" style={{ marginLeft: "10px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                      </svg>
                    </span>
                  </button>
                  <div className="d-flex justify-content-between align-items-start w-100 mt-2">
                    <a
                      href="/forget-password"
                      className="fs-13 text-inc-tundora"
                    >
                      Forgot Password?
                    </a>
                    <a href="/signup" className="fs-13 text-inc-tundora">
                      Create New Account?
                    </a>
                  </div>
                  <div
                    className="mt-3 fs-13 ff-ws text-inc-tundora"
                    style={{ color: "grey" }}
                  >
                    Powered by TASC Outsourcing®
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
};

export default Login;

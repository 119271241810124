import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home";
import PanelWrapper from "./components/wrapper/PanelWrapper";
import ErrorPage from "./components/fallbacks/ErrorPage";
import TrackRequest from "./components/TrackRequest";
import RequestListing from "./components/RequestListing";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgetPassword from "./components/auth/ForgetPassword";
import { AdminSettings } from "./components/settings.jsx/AdminSettings";
import CreatePassword from "./components/auth/CreatePassword";
import VerifyUser from "./components/auth/VerifyUser";
import { Intercom } from "./components/wrapper/Intercom";
import TwoFactor from "./components/TwoFactor";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      // element: <Intercom />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <PanelWrapper />,
          children: [
            {
              path: "",
              element: <Home />,  
            },
            {
              path: "request-list",
              element: <RequestListing />,
            },
            {
              path: "track-request",
              element: <TrackRequest />,
            },
            {
              path: "admin-setting",
              element: <AdminSettings />,
            },
          ],
        },
        {
          path: "signup",
          element: <Register />,
        },
        {
          path: "signin",
          element: <Login />,
        },
        {
          path: "forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "reset-password",
          element: <CreatePassword />,
        },
        {
          path: "incorpify/verify",
          element: <VerifyUser />,
        },
        {
          path: "signin/two-factor",
          element: <TwoFactor />,
        },
        {
          path: "*",
          element: <>NO PAGE FOUND</>,
        },  
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;

import React from "react";
import Hero from "../common/Hero";
import { settings } from "../../utils/tools";
import { useLocation } from "react-router-dom";

const HeadTitle = () => {
  let location = useLocation();
  let hero_description = "";
  let heading = "";
  if (location.pathname === "/") {
    hero_description = settings.hero_description;
    heading = settings.heading;
  } else if (location.pathname === "/request-list") {
    hero_description =
      "Track your company's incorporation process in Saudi Arabia seamlessly with TASC Outsourcing's dedicated tracking platform.";
    heading = "Incorporation Dashboard";
  }
  return (
    <div>
      {location.pathname !== "/request-list" && (
        <p className="lead mt-5 mb-0">
          <span
            className="btn-lg btn-light fw-bold border-white bg-white incorpify-title text-inc-tundora"
            style={{ fontWeight: "700" }}
          >
            Welcome to
          </span>
        </p>
      )}
      <Hero
        heading={heading}
        subheading={hero_description}
        secondSubHeading="The Choice is Yours!"
        forHome={location.pathname === "/"}
      />
    </div>
  );
};

export default HeadTitle;

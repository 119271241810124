import React, { useEffect, useState } from "react";
import { settings } from "../../utils/tools";
import { RightCircleArrow } from "../../assets/Icons";
import { useNavigate } from "react-router-dom";

export const checkTitleColumn = (data, title) => {
  let subitemName = "";
  data.column_values.map((item) => {
    if (item.id === title) {
      subitemName = item.text;
    }
  });
  if (subitemName === "") {
    subitemName = data.name;
  }
  return subitemName;
};

const StepCard = ({ step, count, button_bg, notification, title, statusId }) => {
  const [status, setStatus] = useState("");
  const [dates, setDate] = useState("");
  const navigate = useNavigate();
  const { link_btn_color } = settings;

  useEffect(() => {
    if (step?.column_values.length) {
      step?.column_values.forEach((item) => {
        if (item.id === statusId) {
          setStatus(item.text);
        }
      });
    }
    if (step.updated_at) {
      const date = new Date(step.updated_at);

      // Extract the date part
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
      setDate(formattedDate);
    }
  }, [step]);

  const navigateToDetailsPage = () => {
    localStorage.removeItem("newUpdates");
    localStorage.setItem("subItemId", step.id);
    localStorage.setItem("count", count);
    navigate("/track-request");
  };

  return (
    <div className="w-100 border-bottom text-start p-3 incorpify-stepcard">
      <div className="step-count fs-1 step-col1">
        <span
          className="fw-bolder ff-ws "
          style={{
            fontSize: "78px",
            color:
              status === "Locked" || status === "Awaiting Action"
                ? "#929193"
                : "#434343",
          }}
        >
          {count < 10 ? "0" + count : count}
        </span>
      </div>
      <div className="m-0 p-0 d-flex flex-column align-items-start justify-content-center step-col2">
        <p
          className="fs-4 fw-bolder m-0 p-0 my-1"
          style={{
            color:
              status === "Locked" || status === "Awaiting Action"
                ? "#929193"
                : "#434343",
          }}
        >
          {/* {step.name} */}
          {checkTitleColumn(step, title)}
        </p>
        <p
          className={`status fw-bold  ${
            status.toLowerCase() === "completed"
              ? "text-success"
              : status.toLowerCase() === "stuck" || status.toLowerCase() === "pending" || status.toLowerCase() === "on hold"
              ? "text-danger"
              : status.toLowerCase() === "locked" || status.toLowerCase() === "awaiting action"
              ? "default"
              : "text-warning"
          } m-0 p-0`}
        >
          {status}
        </p>
      </div>
      <div className="d-flex flex-column align-items-start justify-content-center step-col3">
        <strong className="text-secondary my-1">Last Update</strong>
        <small className="text-secondary my-1">{dates}</small>
      </div>

      <div className="step-col4">
        <button
          style={{
            background:
              status === "Locked" || status === "Awaiting Action"
                ? "#929193"
                : button_bg,
            color: link_btn_color,
            position: "relative",
            cursor:
              status === "Locked" || status === "Awaiting Action"
                ? "not-allowed"
                : "pointer",
          }}
          onClick={() =>
            status !== "Locked" &&
            status !== "Awaiting Action" &&
            navigateToDetailsPage()
          }
          className={`hit-loading btn btn-to-link btn-secondary btn-gradiant fw-bold d-flex align-items-center justify-content-around ${
            status !== "Completed" &&
            status !== "Locked" &&
            status !== "Awaiting Action" &&
            notification &&
            "learn-btn"
          }`}
          type="button"
        >
          <span>Updates</span>
          <span
            className="icon-btn_track d-flex align-items-center"
            // style={{ height: "fit-content", width: "22px", paddingLeft: "5px" }}
          >
            <RightCircleArrow />
          </span>
        </button>
      </div>
    </div>
  );
};

export default StepCard;

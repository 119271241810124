import React, { useEffect, useState } from "react";
import HeadTitle from "./home/HeadTitle";
import Card from "./Card";
import { checkIsItemCreated, getUserDetails, userSettingData } from "../utils/tools";
import Loader from "./common/Loader";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const checkItemCreated = async () => {
    setLoading(true);
    await userSettingData();
    await getUserDetails();
    let created = await checkIsItemCreated();
    if (created) {
      navigate("/request-list");
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkItemCreated();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <HeadTitle />
      <div className="mt-3">
        <Card />
      </div>
    </>
  );
};

export default Home;

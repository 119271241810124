import React from "react";
import {
  extractUsernameFromMessage,
  getFirstLettersOfName,
  showUserName,
} from "../../utils/CommonHelper";
import { Clock, IncorpifyTeam } from "../../assets/Icons";
import { getDateAndTime } from "../../utils/formatTime";

const Replies = ({ item, userEmailData }) => {
  let userName = showUserName(item.body, userEmailData);
  return (
    <div className="d-flex" style={{ gap: "5px" }}>
      <div>
        <div
          className="rounded-circle fw-bold text-white d-flex align-items-center justify-content-center"
          style={{
            background: "#ff6221",
            width: "35px",
            height: "35px",
            fontSize: "15px",
            paddingTop: userName === "Incorpify Team" ? "0px" : "2px",
          }}
        >
          {userName === "Incorpify Team" ? (
            <IncorpifyTeam />
          ) : (
            <span>{getFirstLettersOfName(userName, item.body)}</span>
          )}
        </div>
      </div>
      <div>
        <div
          className="rounded-2 px-3 py-2"
          style={{ background: "#6f74900f" }}
        >
          <div className="text-primary">{showUserName(item.body, userEmailData)}</div>
          <div
            className="inc-reply-container message-container"
            style={{ color: "#6F7490" }}
            dangerouslySetInnerHTML={{
              __html: extractUsernameFromMessage(item.body),
            }}
          />
        </div>
        <div>
          <span style={{ color: "#6F7490", fontSize: "14px" }}>
            <Clock width="14px" /> {getDateAndTime(item.created_at)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Replies;

import React from "react";
import { Loading } from "../../assets/Icons";

const Loader = () => {
  return (
    <div id="loading-overlay">
      <div className="spinner">
        <Loading />
      </div>
    </div>
  );
};

export default Loader;
